import { Box, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import ChatbotContext from 'src/context/chatbot';
import { cms } from 'src/helpers/language';
import { useNavigate } from 'react-router-dom';
import { AUTH_HELP } from 'src/routes/routemap';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { Button } from '@pennfoster/pfc-design-system';
import { coBrowsingAtom } from 'src/state';
import { useAtom } from 'jotai';
import CoBrowseDialog from 'src/components/dialogs/cobrowse';

export interface FooterProps {
  helpCenterLink?: string;
  supportCaseLink?: string;
}

export default function HelpFooter({ supportCaseLink }: FooterProps) {
  const { chatbot } = useContext(ChatbotContext);
  const content = cms.content.general.footer;
  const [coBrowsing] = useAtom(coBrowsingAtom);
  const [coBrowseToggle, setCoBrowseToggle] = useState(false);

  const handleCoBrowseDialog = () => {
    setCoBrowseToggle((prev) => !prev)
  };

  const callAdaToggle = (event: React.MouseEvent<HTMLAnchorElement>) => {
    chatbot?.toggle();
    event.preventDefault();
  };

  const navigate = useNavigate();

  return (
    <footer>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Typography>
          {content.assistance_question}
          {content.assistance_option_1}
          <a href={AUTH_HELP} target="_blank" rel="noreferrer">
            {content.assistance_option_1_link_label}
          </a>
          {content.assistance_option_2}
          <a href="#" onClick={callAdaToggle}>
            {content.assistance_option_2_link_label}
          </a>
          {/* {content.assistance_option_3}
        <a href={supportCaseLink} target="_blank" rel="noreferrer">
          {content.assistance_option_3_link_label}
        </a> */}
          {content.assistance_end}
        </Typography>
        {coBrowsing && (
          <Button sx={{ ml: 2 }} onClick={() => handleCoBrowseDialog()}>
            <QuestionAnswerIcon />
          </Button>
        )}
        <CoBrowseDialog toggle={coBrowseToggle} handleClose={handleCoBrowseDialog}/>
      </Box>
    </footer>
  );
}
